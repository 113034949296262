<template>
    <div>

        <div class="row section-header" >
            <div class="col-md-3 section-title">
                <h3>Acquisti</h3>
            </div>

            <div class="col-md-6">
                <search-bar @search-name="searchName($event)" placeholder="Cerca per nome"/>
            </div>

            <div class="col-md-3 text-right" style="">
            </div>
        </div>

        <div class="top-content ">

        </div>

        <div class="content">
            <!-- RIASSUNTO -->
            <div class="index" :class="{ 'opened': (currentId == -1) }">

                <div class="card">
                    <b-row>
                        <b-col sm="3">
                            <h5>Totale acquisti</h5>
                            <h2>{{ summary.totalPurchases }}</h2>
                        </b-col>
                        <b-col sm="3">
                            <h5>Ore guida acquistate</h5>
                            <h2>{{ summary.totalPracticeHours }}</h2>
                        </b-col>
                        <b-col sm="3">
                            <h5>Ore simulatore acquistate</h5>
                            <h2>{{  summary.totalSimulatorHours }}</h2>
                        </b-col>
                        <b-col sm="3">
                            <h5>Incasso totale</h5>
                            <h2>€ {{ summary.totalAmount }}</h2>
                        </b-col>
                    </b-row>
                </div>
                <br/>


                <div class="card">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="purchases.length"
                        :per-page="perPage"
                        limit="15"
                        align="right"
                        v-if="purchases.length > perPage"
                    ></b-pagination>

                    <table class="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col" style="">Studente</th>
                            <th scope="col">Nome</th>
                            <th scope="col" style="width:80px" class="text-center">Qtà</th>
                            <th scope="col" style="width:100px" class="text-right">Totale</th>
                            <th scope="col" class="text-center" style="width:80px">Pagata</th>
                            <th scope="col" class="text-center" style="width:80px">Fatturata</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :class="{ 'table-primary': item.id == currentId }"
                            v-for="item in lists"
                            :key="item.id"
                            @click="setActivePurchase(item)"
                        >
                            <td>{{ item.customerName }}</td>
                            <td>{{ item.item }}</td>
                            <td class="text-center">{{ item.qty }}</td>
                            <td class="text-right">{{ item.total | toCurrency }}</td>
                            <td class="text-center"> <b-icon icon="patch-check" v-if="item.status == 'paid'" class="text-success"/></td>
                            <td class="text-center"> <b-icon icon="check2" v-if="item.invoiced"/></td>
                        </tr>
                        </tbody>
                    </table>
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="purchases.length"
                        :per-page="perPage"
                        limit="15"
                        align="right"
                        v-if="purchases.length > perPage"
                    ></b-pagination>

                    <p v-if="purchases.length == 0">Ancora nessun acquisto</p>
                </div>
            </div>

            <div class="editor" :class="{ 'opened': (currentId >= 0) }">
                <div class="top-editor">
                    <b-button variant="link" @click="closeEditor"  :disabled="this.$store.state.loading"><b-icon icon="x"/> Chiudi</b-button>
                </div>
                <div v-if="currentId > 0">
                    <purchase-view :purchase="currentPurchase" :key="currentId"
                                                   v-on:updated-purchase="closeEditor()"
                                                   v-on:closed-editor="closeEditor()"/>
                </div>
            </div>


        </div>
    </div>

</template>

<script>
import PurchaseDataService from "@/components/purchase/PurchaseDataService";
import PurchaseView from "@/components/purchase/PurchaseView";
import SearchBar from "@/components/layout/SearchBar";
import moment from 'moment';

export default {
    name: "purchase-index",
    components: {SearchBar, PurchaseView},
    data() {
        return {
            purchases: [],
            currentPurchase: null,
            summary: {},
            currentId: -1,
            edit: false,
            generate: false,
            perPage: 25,
            currentPage: 1
        };
    },
    computed: {
        lists () {
            const items = this.purchases
            // Return just page of items needed
            return items.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage
            )
        },
        totalRows () {
            return this.purchases;
        }
    },
    methods: {
        retrievePurchases() {
            PurchaseDataService.getAll()
                .then(response => {
                    this.purchases = response.data;
                    this.updateSummary();
                })
                .catch(e => {
                    console.log(e);
                });
        },

        refreshList() {
            this.retrievePurchases();
            this.currentPurchase = null;
            this.currentId = -1;
        },

        setActivePurchase(item) {
            this.currentPurchase = item;
            this.currentId = item.id;
            this.edit = false;
        },

        setEdit() {
            this.edit = true;
        },
        setGenerate() {
            this.generate = true;
        },

        searchName(name) {
            this.currentPurchase = null;
            this.currentId = -1;
            PurchaseDataService.findByName(name)
                .then(response => {
                    this.purchases = response.data;
                    this.updateSummary();
                })
                .catch(e => {
                    console.log(e);
                });
        },

        closeEditor() {
            if (this.edit == true) {
                this.edit = false
                return;
            }
            if (this.generate == true) {
                this.generate = false
                return;
            }
            this.currentPurchase = null;
            this.currentId = -1;
        },
        formatDate(datetime) {
            if (datetime) return moment(datetime).format('DD/MM/YYYY HH:mm');
            else return '';
        },

        updateSummary() {
            this.summary = {
                totalPurchases: this.purchases.length,
                totalPracticeHours: 0,
                totalSimulatorHours: 0,
                totalAmount: 0,
            }

            let practices = this.purchases.filter(p => p.item == 'Ore guida reali');
            let simulator = this.purchases.filter(p => p.item == 'Ore guida simulatore');
            for (let item of practices) {
                this.summary.totalPracticeHours += item.qty;
                this.summary.totalAmount += Number(item.total);
            }
            for (let item of simulator) {
                this.summary.totalSimulatorHours += item.qty;
                this.summary.totalAmount += Number(item.total);
            }
        },
    },
    mounted() {
        this.retrievePurchases();
    }
};
</script>

<style scoped>
.editor-edit.opened {
    top: 20vh;
}
.editor-edit {
    height: 80vh;
}
.editor-generate.opened {
    top: 50vh;
}
.editor-generate {
    height: 50vh;
}
.license-code {
    display: inline-block;
    width: 100px;
    padding: 4px;
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: rgba(205, 182, 29, 0.25);
    color: #5e5516;
    font-weight: bold;
    font-size: .9em;
}
.license-code.activated {
    background-color: #e0e0e0;
    color: #7e7e7e;
}
.table td, .table th {
    padding: 0.25rem;
}
</style>
