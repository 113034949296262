<template>
    <div>
        <div class="card">
            <h1>Dettagli dell'acquisto</h1>
            <br>
            <table class="table table-bordered">
                <tr>
                    <td style="vertical-align:top; width: 180px">
                        <dl class="row">
                            <dt class="col-sm-3">Data: </dt>
                            <dd class="col-sm-9">{{ purchase.createdAt | moment }}</dd>
                        </dl>
                    </td>
                    <td colspan="3">
                        <dl class="row">
                            <dt class="col-sm-3">Nome</dt>
                            <dd class="col-sm-9"><b>{{ purchase.customerName }}</b></dd>

                            <dt class="col-sm-3">Indirizzo</dt>
                            <dd class="col-sm-9">{{ purchase.customerAddress }}</dd>

                            <dt class="col-sm-3">Codice fiscale</dt>
                            <dd class="col-sm-9">{{ purchase.customerCF }}</dd>
                        </dl>
                    </td>
                </tr>
            </table>
            <table class="table table-bordered">
                <tr>
                    <td><b>Item</b></td>
                    <td style="width: 80px;" class="text-center"><b>Qtà</b></td>
                    <td style="width: 120px;" class="text-right"><b>Prezzo</b></td>
                    <td style="width: 120px;" class="text-right"><b>Totale</b></td>
                </tr>
                <tr>
                    <td>{{ purchase.item }}</td>
                    <td style="width: 80px;" class="text-center">{{ purchase.qty }}</td>
                    <td style="width: 120px;" class="text-right">{{ purchase.price | toCurrency }}</td>
                    <td style="width: 120px;" class="text-right">{{ purchase.total | toCurrency }}</td>
                </tr>
                <tr>
                    <td colspan="2" class="text-center">
                        <b-button variant="success" size="" @click="setInvoiced" v-if="!purchase.invoiced">
                            Segna come fatturata
                        </b-button>
                        <div class="alert alert-success text-center" style="margin-bottom: 0" v-else>
                            FATTURATA
                            (<a href="#" class="small" @click="setUninvoiced" v-if="purchase.invoiced">
                                <b-icon icon="x"/> Ripristina come non fatturata
                            </a>)
                        </div>
                    </td>
                    <td style="width: 120px;" class="text-right"><b>TOTALE</b></td>
                    <td style="width: 120px;" class="text-right"><b>{{ purchase.total | toCurrency }}</b></td>
                </tr>
            </table>
            <br>

            <br><br>
        </div>

    </div>
</template>

<script>
import PurchaseDataService from "@/components/purchase/PurchaseDataService";
import moment from "moment"

export default {
    name: "purchase-view",

    data() {
        return {
            message: '',
            errorMsg: ''
        };
    },
    props: ["purchase"],
    methods: {
        setInvoiced() {
            this.purchase.invoiced = true;
            PurchaseDataService.setInvoiced(this.purchase.id)
                .then(response => {
                    this.$emit('purchase-updated')
                })
                .catch(e => {
                    this.errorMsg = e.response.data.message;
                    console.log(e);
                });
        },
        setUninvoiced() {
            this.purchase.invoiced = false;
            PurchaseDataService.setUninvoiced(this.purchase.id)
                .then(response => {
                    this.$emit('purchase-updated')
                })
                .catch(e => {
                    this.errorMsg = e.response.data.message;
                    console.log(e);
                });
        },
    },
    filters: {
        moment: function (date) {
            return moment(date).format('DD/MM/YYYY');
        },
    },
    mounted() {
    }
};
</script>

<style scoped>
    dt { font-size: .9em; text-align: right }
</style>
