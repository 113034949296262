import http from "../../helpers/http-common";

class PurchaseDataService {
    getAll() {
        return http.get("/purchases");
    }

    get(id) {
        return http.get(`/purchases/${id}`);
    }

    create(data) {
        return http.post("/purchases", data);
    }

    setInvoiced(id) {
        return http.put(`/purchases/invoiced/${id}`);
    }

    setUninvoiced(id) {
        return http.put(`/purchases/uninvoiced/${id}`);
    }

    findByName(name) {
        return http.get(`/purchases?name=${name}`);
    }
}

export default new PurchaseDataService();
